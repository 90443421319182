// import React from "react";
// import parse from 'html-react-parser'
// import { Link } from "react-router-dom";
// import useNews from "./useNews";
// import Trainingclassloader from "../../Trainingclass/Trainingclassloader";

// const News = () => {
//   const { news, setNews, loading, setLoading } = useNews();
//   console.log("news is", news)
//   return (
//     <>
//       <section className="main-section-part" id="news">
//         <div>
//           <div className="container">
//             <div className="section-title">
//               <h2>Latest News and events</h2>
            
//             </div>
//             <div className="row mb-5">
//               {loading? <><Trainingclassloader/></> : <>
//               {
//                 news?.map((elem) => {
//                   return (
//                     <div className="col-md-3 mb-3">
//                       <div className="bg-white news-section">
//                         {/* <h4>Call the callback functions that have been</h4> */}
//                         <h4 className="training-heading">{elem.title}</h4>
//                         {/* <small>March 25,2022</small>*/}
//                         <p className="training-paragraph">{
//                           parse(elem.description || "")
//                 }</p> 
//                         <Link to={`/newsdetailspage/${elem.slug}`} > <span>
//                           <i class="fa-solid fa-arrow-right"></i>
//                         </span>
//                         </Link>
//                       </div>
//                     </div>
//                   )
//                 })
//               }
//               </>
//               } 
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default News;

import React from "react";
import parse from 'html-react-parser'
import { Link } from "react-router-dom";
import useNews from "./useNews";
import Trainingclassloader from "../../Trainingclass/Trainingclassloader";

const News = () => {
  const { news, setNews, loading, setLoading } = useNews();
  console.log("news is", news)
  return (
    <>
      <section className="main-section-part" id="news">
        <div>
          <div className="container">
            <div className="section-title">
              <h2>Latest News and events</h2>
            
            </div>
            <div className="row mb-5">
              {loading? <><Trainingclassloader/></> : <>
              {
                news?.map((elem) => {
                  return (
                    <div className="col-md-3 mb-3">
                      <div className="bg-white news-section">
                        <h4 className="training-heading">{elem.title}</h4>
                        <p className="training-paragraph">{
                          parse(elem.description || "")
                }</p> 
                        <Link to={`/newsdetailspage/${elem.slug}`} > <span>
                          <i class="fa-solid fa-arrow-right"></i>
                        </span>
                        </Link>
                      </div>
                    </div>
                  )
                })
              }
              </>
              }
              </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default News;
