import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FooterAPI } from "./FooterAPI";

const Footer = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    FooterAPI.get()
      .then((res) => {
        console.log(res.data.data.setting);
        let response = res.data.data.setting;
        setData(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "error message");
        setLoading(false);
      });
  }, []);

  return (
    <>
      <footer>
        <div className="container ">
          <div className="row pt-4">
            <div className="col-md-4">
              {/* <p>
                Abundant rainfall is created by the nearby ocean, with as much
                as 122 inches of precipitation each year. Also, as the cool
                moist breeze.
              </p> */}

              <div className="footer-icon">
                <h3 className="footer-header">Find us on</h3>
                <ul>
                  <li>
                    <a href={data.facebook_link} target="_blank">
                      <i class="fa-brands fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href={data.youtube_link} target="_blank">
                      <i class="fa-brands fa-youtube"></i>
                    </a>
                  </li>
                  <li>
                    <a href={data.instagram_link} target="_blank">
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href={data.twitter_link} target="_blank">
                      <i class="fa-brands fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={data.linkedin_link} target="_blank">
                      <i class="fa-brands fa-linkedin-in"></i>
                    </a>
                  </li>
                </ul>

                <h3 className="footer-header mt-5">Visit Us</h3>
                <ul>
                  <li>
                    <p className="ms-3">Subidhanagar-32 , Tinkune, Kathmandu, Nepal</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-2 col-6 footer-main">
              <h3 className="footer-header quick-links">Quick Links</h3>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/aboutpage">About Us</Link>
                </li>
                <li>
                  <Link to="/testimonials">Testimonials</Link>
                </li>
                <li>
                  <Link to="/blogs">Blogs</Link>
                </li>
                <li>
                  <Link to="/news&events">News and Events</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-2 col-6 footer-main">
              {/* <ul>
                <li>
                  <Link to="/blogs">Blogs</Link>
                </li>
                <li>
                  <Link to="/news&events">News and Events</Link>
                </li>
                <li>
                  <Link to="/appointment">Contact</Link>
                </li>
                
              </ul> */}
            </div>
            <div className="col-md-3">
              <div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14134.065952909976!2d85.32395955!3d27.670427399999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2snp!4v1652180582627!5m2!1sen!2snp" width="100%" height="250" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
          </div>
          <hr />
          <div className="d-flex rights">
            <div>
              <p>
                <span>&copy;</span> {new Date().getFullYear()} - All rights Reserved site created by <a href="">Creatu Developers</a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
