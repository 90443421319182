import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import parse from 'html-react-parser'
import './NewsDetails.css'
import { Link } from 'react-router-dom'
import Canada from '../../Components/assets/images/canada.jpg'
import News from '../../Components/assets/images/news01.jpg'
import useNewsdetails from './useNewsdetails'
import Newsloader from './Loader/Newsloader'
import NewsDetailsloader from './Loader/NewsDetailsloader'


const NewsDetailsPage = () => {
  const { newsDetail, news, setNews, slug, setSlug, latest, setLatest, loading, setLoading } = useNewsdetails();
  const params = useParams();


  useEffect(() => {
    if (params.slug) {
      setSlug(params.slug)
    }
  }, [params])


  return (
    <>
      <div >
        <div className="section-image card-overlay">
          <img src={Canada} alt="" className="img-fluid" />
        </div>
        <div className="section-study">
          <h1>Latest News and Event</h1>
        </div>
      </div>
      <div className="container">

        <div className="row">
          {
            loading ? <> <NewsDetailsloader /></> :
              <>
                <div className="col-md-8">
                  <div>
                    <img src={newsDetail?.image} alt="news-event" className='news-event-img' />
                  </div>

                  {/* <p className='date can-mar'>20 March, 2022</p> */}
                  <h3 className='intro mb-2 mt-2'>
                    {/* Calls the callback functions that have been */}
                    {newsDetail?.title}
                  </h3>
                  {/* <p className='card-college-para mb-4'>
           Business is the activity of making one’s living or making money by producing or buying and selling products (goods and services).Simply put, it is “any activity or enterprise entered into for profit. It does not mean it is a company, a corporation, partnership, or have any such formal organization, but it can range from a street peddler to General Motors.”[5] The term is also often used colloquially (but not by lawyers or public officials) to refer to a company, but this article will not deal with that sense of the word.
           </p> */}
                  <p className='card-college-para mb-2 mt-2'>
                    {parse(newsDetail?.description || "")}
                  </p>
                </div>
                <div className="col-md-4">
                  <div className='recent-news'>
                    <h2>Recent Posts</h2>
                    <hr />
                    <ul>

                      {
                        latest.map((elem) => {
                          return (
                            <li className="dropdown-list"><Link to={`/NewsDetailsPage/${elem.slug}`}>{elem?.title}</Link></li>

                          )
                        }
                        )
                      }
                    </ul>
                  </div>
                </div>
              </>
          }

        </div>
      </div>
    </>
  )
}



export default NewsDetailsPage