import React from 'react'
import parse from 'html-react-parser';
import './NewsDetails.css'
import { Link } from 'react-router-dom'
import Canada from '../../Components/assets/images/canada.jpg'
import News from '../../Components/assets/images/news01.jpg'
import useNewsdetails from './useNewsdetails'
import Newsloader from './Loader/Newsloader';

const NewsDetails = () => {
  const { news, setNews, loading, setLoading,latest,setLatest } = useNewsdetails();

  // console.log("haha hehe is",news)
  // console.log('news',news)
  return (
    <>
      <div>
        <div className="section-image card-overlay">
          <img src={Canada} alt="" className="img-fluid" />
        </div>
        <div className="section-study">
          <h1>Latest News and Event</h1>
        </div>
      </div>
      <div className="container">
        <div className="row">
          {loading? <><Newsloader/></> : 
          <>
          {
            news?.map((elem) => {
              return (
                <>
                  <div className="col-md-4">
                    <div className='latest-img'>
                      <img src={elem?.image} alt="news-event" className='img-fluid' />
                    </div>

                    <p className='date can-mar'>{elem?.created_at.split("T")[0]}</p>
                    <h3 className='intro'>
                     {
                      elem?.title
                     }
                    </h3>
                  <p className='training-paragraph'>
                  {
                      parse(elem?.description)
                    }
                  </p>
                    <Link to={`/NewsDetailsPage/${elem?.slug}`}>
                      <p className="readmore card-college-para ">Read more</p>
                    </Link>
                  </div>
                </>
              )
            })
          }
          </>
          }

          {loading === false ? <div className="col-md-4">
            <div className="recent-news">
              <h2>Recent Posts</h2>
              <hr />
              <ul>
                {
                   latest?.map((elem) => {
                    return (
                      <li className="dropdown-list"><Link to={`/NewsDetailsPage/${elem.slug}`}>{elem?.title}</Link></li>
                     
                    )
                  }
                  )
                }
              </ul>
            </div>
          </div> : null}

        </div>
      </div>
    </>
  );
};

export default NewsDetails;
