import React from 'react'
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const Mainbannerloader = () => {
  return (
  <>
<div className="row">
      <div className="col-md-12">
        <Stack spacing={1}>
          <Skeleton variant="rectangular" width={"100%"} height={700} />

          <Skeleton variant="text" width={"100%"} />
          <Skeleton variant="text" width={"100%"} />
          {/* <Skeleton variant="text" width={} /> */}
        </Stack>
      </div>
    
    /</div>
  </>
  )
}

export default Mainbannerloader