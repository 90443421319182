import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
const InterviewLoader = () => {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <Stack spacing={1}>
            <Skeleton variant="rectangular" width={'100%'} height={300} />
            {/* <Skeleton variant="text" width={350} />
            <Skeleton variant="text" width={350} /> */}
            {/* <Skeleton variant="text" width={350} /> */}
          </Stack>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-md-12">
          <Stack spacing={1}>
            {/* <Skeleton variant="rectangular" width={'100%'} height={300} /> */}
            <Skeleton variant="text" width={'100%'} />
            <Skeleton variant="text" width={'100%'} />
            <Skeleton variant="text" width={'100%'} />
          </Stack>
        </div>
      </div>
    </>
  );
};

export default InterviewLoader;
