import axios from 'axios';
import React, { useEffect, useState } from 'react'

const useBlog = () => {
  
    const[blog,setBlog]=useState([]);
    const [blogDetail,setBlogDetail]=useState([null]);
    const [slug,setSlug]=useState([null])
    const[loading,setLoading]=useState(false);
    useEffect(()=>{
        setLoading(true);
        axios({
            url:`${process.env.REACT_APP_API_URL}/get-blogs`,
            method:"GET"
        }).then((res)=>{
            console.log(res.data.data.blogs)
            let response = res.data.data.blogs ;
            setLoading(false);
            setBlog(response);
        }).catch((err)=>{
            console.log(err,"Your error is")
            setLoading(false);
        })

        },[]
    )
//single fetch
    useEffect(()=>{
        setLoading(true)
      if(slug){
        axios({
            url:`${process.env.REACT_APP_API_URL}/getSingle-blog/${slug}`,
            method:"GET"
        
        }).then((res)=>{
            // console.log(res.data.data.blog)
            let response = res.data.data.blog ;
            setBlogDetail(response);
            setLoading(false)
        }).catch((err)=>{
            console.log(err,"Your error is")
            setLoading(false)
        })
      }

        },[slug]
    )

  return{
    blog,
    setBlog,
    setSlug,
    slug,
    blogDetail,
    setBlogDetail,loading,setLoading
  }
}

export default useBlog