import React from 'react'
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const StudyAbroadLoader = () => {
  return (
   <>
    <div className="col-md-12">
        <Stack spacing={1}>
        <Skeleton variant="text" width={"30%"} />
          <Skeleton variant="rectangular" width={"100%"} height={400} />

         
          <Skeleton variant="text" width={"100%"} />
          <Skeleton variant="text" width={"100%"} />
          <Skeleton variant="text" width={"100%"} />
          <Skeleton variant="text" width={"100%"} />
          <Skeleton variant="text" width={"100%"} />
          <Skeleton variant="text" width={"100%"} />
          <Skeleton variant="text" width={"100%"} />
          <Skeleton variant="text" width={"100%"} />
          <Skeleton variant="text" width={"100%"} />
          <Skeleton variant="text" width={"100%"} />
          <Skeleton variant="text" width={"100%"} />
          <Skeleton variant="text" width={"100%"} />
          <Skeleton variant="text" width={"100%"} />
          <Skeleton variant="text" width={"100%"} />
          <Skeleton variant="text" width={"100%"} />
    
        </Stack>
      </div>
   </>
  )
}

export default StudyAbroadLoader