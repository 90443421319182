import axios from 'axios';
import React, { useEffect, useState } from 'react'


const useMainbanner = () => {
    const [banner,setBanner]=useState([]);
    const[loading,setLoading]=useState(false);
useEffect(()=>{
    setLoading(true);
    axios({
        url:`${process.env.REACT_APP_API_URL}/carousels/getall-carousels`,
        method:'GET'
       
    }).then((res)=>{
        console.log(res.data.data.carousels)
        let response = res.data.data.carousels;
        setBanner(response);
        setLoading(false)
    }).catch((err)=>{
        console.log("your err is ",err)
        setLoading(false);
    })
},[])
  return {
banner,
setBanner,
loading,setLoading
  }
}

export default useMainbanner