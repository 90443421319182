import React from "react";
import "./Appointment.css";
import { useState } from "react";
import { useNavigate, Link, Navigate } from "react-router-dom";
import { AppointmentAPI } from "./AppointmentAPI";
import { LoadingButton } from "@mui/lab";
import { ValidContact, ValidEmail } from "../../Components/helpers/Validators";
import { getNativeSelectUtilityClasses } from "@mui/material";
import { useSnackbar } from "notistack";
import axios from "axios";

const ApplyOnline = () => {

    const navigate = useNavigate();

    const initialValue = {
        name : "",
        phone_no : "",
        age : "",
        email : "",
        address : "",
        country:"",
        file:[]
    }

    const [userData, setUserData] = useState(initialValue)

  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const inputHandler = (e) => {
    e.preventDefault();
    setUserData({...userData,[e.target.name]:e.target.value})
  }

  const [image, setImage] = useState([])

  const fileInputHandler = (e) => {
    e.preventDefault();
    if(e.target.name === 'file[]'){
      setImage(e.target.files)
      setUserData({...userData,file:URL.createObjectURL(e.target.files)})
    }
  };

  const postSubmit = (e) => {
    e.preventDefault();
    if(userData.name === "" || userData.phone_no === "" || userData.age === "" || userData.email === "" || userData.address === "" || userData.country === "" || userData.file === []){
        enqueueSnackbar("Empty field detected please fill every filed",{variant:"error"})
    }
    else{
        const myData = new FormData();
    myData.append("name",userData.name)
    myData.append("phone_no",userData.phone_no)
    myData.append("age",userData.age)
    myData.append("email",userData.email)
    myData.append("address",userData.address)
    myData.append("country",userData.country)
    for(let i= 0;i<image.length ;i++){
        myData.append("file[]",image[i])
    }
    // myData.append("file[]",image)
    setLoading(true);
    axios({
        method:"POST",
        url:`${process.env.REACT_APP_API_URL}/store-online-application`,
        data:myData
    }).then((res)=>{
        setUserData(res.data)
        setLoading(false)
        enqueueSnackbar("Your Online Application was sent.",{variant:"success"})
        navigate("/")
    }).catch((err)=>{
        enqueueSnackbar(err.message,{variant:"error"})
        setLoading(false)
    })
    }
  }

  return (
    <>
      <div className="container contact-form appointment-section-field">
        <div classname="section-title">
          <h2 className="text-center can-mar inquiry-now">Appointment </h2>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="">Name</label>
            <br />
            <input
              type="text"
              name="name"
              id=""
              placeholder="Name"
              value={userData.name}
              onChange={inputHandler}
            />
          </div>

          <div className="col-md-4">
            <label htmlFor="">Phone No</label>
            <br />
            <input
              type="number"
              name="phone_no"
              id=""
              placeholder="Number"
              min="10"
              max="100"
              value={userData.phone_no}
              maxLength={10}
              onChange={inputHandler}
            />
          </div>

          <div className="col-md-2">
            <label htmlFor="">Age</label>
            <br />
            <input
              type="number"
              name="age"
              id=""
              placeholder="Age"
              value={userData.age}
              onChange={inputHandler}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <label htmlFor="">Email</label>
            <br />
            <input
              type="email"
              placeholder="Email"
              name="email"
              value={userData.email}
              onChange={inputHandler}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="">Address</label>
            <br />
            <input
              type="text"
              placeholder="Address"
              name="address"
              value={userData.address}
              onChange={inputHandler}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="">Country to apply</label>
            <br />
            <select
              name="country"
              id=""
              value={userData.country}
              onChange={inputHandler}
            >
              <option selected value="">
                Countries
              </option>
              <option value="Australia">Australia</option>
              <option value="UK">Uk</option>
              <option value="Canada">Canada</option>
              <option value="Norway">Norway</option>
            </select>
          </div>
          <div className="col-md-6">
            <label htmlFor="">Upload File (You can select multiple files)</label>
            <br />
            <input
              type="file"
              name="file[]"
              placeholder="upload file"
              multiple
              onChange={fileInputHandler}
            />
          </div>
        </div>

        <LoadingButton
          className="btn btn-main ml-2"
          type="submit"
          loading={loading}
          onClick={postSubmit}
        >
          Apply
        </LoadingButton>
      </div>
    </>
  );
};

export default ApplyOnline;
