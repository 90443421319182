import React from 'react'
import parse from 'html-react-parser'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import InterviewLoader from './InterviewLoader'
import Image1 from '../../Components/assets/images/canada.jpg'
import Image2 from '../../Components/assets/images/student-img3.jpeg'
import useNav from '../../Components/Navbar/useNav'
import './Interviewpage.css'


export default function Interviewpage() {
    const {servicedetail,setServicedetail,id,setId,loading,setLoading}=useNav();
    const params =useParams();
    useEffect(()=>{
        if(params.id){
            setId(params.id)
        }
    },[params])
    // console.log("params slug is",params.id)
    // console.log("setService detail is",setServicedetail)
    // console.log("Service detail is",servicedetail)
    return (
        <>
            <section>
            {
                loading? <><InterviewLoader /></>:<>
                      <div>
                    <div className='section-image card-overlay'>
                        <img src={servicedetail?.service_image} alt="" className='img-fluid' />
                    </div>
                    <div className='section-study'>
                        <h1>{servicedetail?.service_name}</h1>
                    </div>
                </div>
             
                 <div className="container">
                    <div className="overview ">
                        {/* <h2>Overview</h2> */}
                    </div>
                    <div className="row">
                        <div className="col-lg-12 higher ">
                            <p>
                              {
                                parse(servicedetail?.service_description || "")
                              }
                            </p>
                        </div>
                      
                    </div>
                </div>
                </>

               }
                  
              
            </section>

        </>
    )
}
