import React from 'react'
import Image1 from '../../assets/images/slide-img1.jpg'
import Image2 from '../../assets/images/slide-img2.jpg'
import Image3 from '../../assets/images/slide-img3.jpg'
import useMainbanner from './useMainbanner'
import Mainbannerloader from './mainbannerloader'


export default function Mainbanner() {
    const { banner, setBanner, loading, setLoading } = useMainbanner();


    console.log('banner is ', banner)

    return (
        <>
            <div className='dashboard-banner'>
            {
                loading ? <><Mainbannerloader/></> : <>

                    <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className='overlay'></div>
                                <div className='carousel-img'>
                                    <img src={banner[0]?.image} className="d-block w-100" alt="image" />
                                </div>
                                <div class="carousel-caption d-none d-md-block">
                                    <div className="carousel-container">
                                    <h2><span>.</span>{banner[0]?.title}</h2>
                                        <h6>{banner[0]?.slug}</h6>
                                        <div className="clearfix" />
                                    </div>
                                </div>
                            </div>
                            {banner?.slice(1, banner.length).map((item) => (
                                <div className="carousel-item ">
                                    <div className='overlay'></div>
                                    <div className='carousel-img'>
                                        <img src={item.image} className="d-block w-100" alt="..." />
                                    </div>
                                    <div class="carousel-caption d-none d-md-block">
                                        <div className="carousel-container">
                                            <h2><span>.</span>{item?.title}</h2>
                                            <h6>{item?.slug}</h6>
                                            <div className="clearfix" />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>


                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true" />
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true" />
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                </>
            }
            </div>

















        </>
    )
}
