import './App.css';
import Footer from './Components/Footer/Footer';
import Navbar from './Components/Navbar/Navbar';
import Homerouting from './HomeRouting/Homerouting';
// import Contactform from './Pages/Contactform';


// import StudyAbroaddetails from './Pages/Blogpage/StudyAbroaddetails/StudyAbroaddetails';
// import Blogpage from './Pages/Blogpage/Blogpage';
// import Testimonialspage from './Pages/Testimonialspage/Testimonialspage';


function App() {
  return (
    <>
   <Navbar/>
   <Homerouting/>
   <Footer />
   {/* <Testimonialspage /> */}
   {/* <Blogpage/> */}
   {/* <StudyAbroaddetails /> */}
   {/* <Appointment /> */}
   {/* <Ielts/> */}
   {/* <Contactform/> */}
   </>


  );
}

export default App;
