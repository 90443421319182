
import React from "react";
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import useTraining from "./useTraining";
import TrainingLoader from "./trainingloader";

export default function Training() {

  const { abroad, setAbroad, setId, loading, setLoading } = useTraining()



  return (
    <>
      <section className="main-section-part" id="study_abroad">
        <div className="container">
          <div className="row">
            <div className="section-title">
              <h2>Study Abroad</h2>
            </div>
          </div>
          {/* <div className="row blurb-row gx-5"> */}
          <div className="row blurb-row ">
            {loading ? <> <TrainingLoader /> </> : <>
              {
                abroad?.map((item) =>
                (
                  <div className="col-lg-4 col-md-4 col-sm-6">

                    <div className="blurb-content-card zoom ">
                      <div className="blurb-content">

                        {/* <Link to={`/${params.id}`}>  */}
                        <div className="blurb-img">
                          <img src={item.destination_image} alt="image01" />
                        </div>
                        <div className="blurb-container">
                          <h4>{item.destination_title}</h4>
                          <div className="">
                            <Link to={`/studyabroad/${item.id}`}>
                              <a href="#" class="link-success read-button">Read More&nbsp;<i class="fa-solid fa-angle-right"></i> </a>
                            </Link>
                          </div>


                          {/* <p>
                            {
                              parse(item.description.substr(0, 500) + "..." || "")
                            }
                          </p> */}
                          {/* {item.description} */}

                        </div>


                        {/* <div className="">
                          <Link to={`/studyabroad/${item.id}`}>
                            <div className="about-button read-button">Read More</div>
                          </Link>
                        </div> */}
                      </div>
                    </div>
                  </div>
                )
                )
              }
            </>
            }

          </div>
        </div>
      </section>
    </>
  );
}
