import axios from 'axios';
import React, { useEffect, useState } from 'react'

const useNewsdetails = () => {
    const[news ,setNews]=useState([]);
    const[latest,setLatest]=useState([]);
    const [newsDetail,setNewsDetail]=useState(null);
    const [slug,setSlug]=useState(null);
    const[loading, setLoading]= useState(false)

    useEffect(()=>{
        setLoading(true);
        axios({
            url:`${process.env.REACT_APP_API_URL}/news_and_event`,
            method:'GET'
        }).then((res)=>{
            // console.log(res.data.data)
            let response =res.data.data.newsAndEvents;
            setNews(response)
            setLoading(false);
        }).catch((err)=>{
            console.log(err,"Your error is")
            setLoading(true);
        })


        axios({
            url:`${process.env.REACT_APP_API_URL}/latest_news_and_event`,
            method:'GET'
        }).then((res)=>{
            // console.log(res.data.data)
            let response =res.data.data.newsAndEvents;
            setLatest(response)
        }).catch((err)=>{
            console.log(err,"Your error is")
        })
    },[])
   

    
    //sinle fetch
    useEffect(()=>{
            if(slug){
                setLoading(true)
                axios({
                    url:`${process.env.REACT_APP_API_URL}/news_and_event/${slug}`,
                    method:'GET'
                }).then((res)=>{
                    // console.log(res.data.data.newsAndEvent);
                    let response = res.data.data.newsAndEvent;
                    setNewsDetail(response)
                    setLoading(false)
                }).catch((err)=>{
                    console.log(err,"server error");
                    setLoading(false);
                })
            }
        
    },[slug])

  



  return {
    slug,
    setSlug,
    newsDetail,
    setNewsDetail,
    news,
    setNews,
    latest,
    setLatest,
    loading,
    setLoading
  }
  
  
}

export default useNewsdetails