import React, { useState, useEffect } from "react";
import Testii from "../../assets/images/about.jpg";
import Schedule from "../Schedule/Schedule";
import Appointment from "../../../Pages/Appointment/Appointment";
import useTestimonial from "../../../Pages/Testimonialspage/useTestimonial";

const Testimonials = () => {
  const { monials, setMonials, loading, setLoading } = useTestimonial();

  return (
    <>
      <section id="testimonial">
        <div className="testy">
          <div className="container fluid">
            <div className="row testimonial-main">
              <div className="col-md-6 schedule-banner1">
                <div className="section-title mb-5 ">
                  <h2 className="text-center">Testimonials</h2>
                </div>
                <div
                  id="carouselExampleCaptions"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  {/* <div className="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={0} className="active" aria-current="true" aria-label="Slide 1" />
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={1} aria-label="Slide 2" />
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={2} aria-label="Slide 3" />
        </div> */}
                  <div className="">
                    {loading ? (
                      <>loading</>
                    ) : (
                      <>
                        {monials?.map((elem) => {
                          return (
                            <>
                              <div className="carousel-item active testimonial">
                                <img
                                  src={elem.image}
                                  className="testimonial-img"
                                  alt="..."
                                />
                                <div className=" testimonial-card ">
                                  <h3>{elem.name}</h3>
                                  <h4>{elem.institution_name}</h4>
                                  <p>
                                    <span className="quote">
                                      {" "}
                                      <sup>
                                        {" "}
                                        <i className="fa-solid fa-quote-left" />
                                      </sup>
                                    </span>
                                    {elem.description}{" "}
                                    <span className="quote">
                                      <sup>
                                        {" "}
                                        <i className="fa-solid fa-quote-right" />
                                      </sup>
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </>
                    )}
                    {/* <div className="carousel-item active testimonial">
                      <img src={Testii} className="testimonial-img" alt="..." />
                      <div className=" testimonial-card ">
                        <h3>Saul Goodman</h3>
                        <h4>CEO &amp; Founder</h4>
                        <p>       
                          <span className="quote">
                            {" "}
                            <sup> <i className="fa-solid fa-quote-left" /></sup>
                          </span>
                          Some representative placeholder content for the first
                          slide Lorem ipsum dolor sit amet consectetur
                          adipisicing elit. Enim alias beatae laboriosam
                          perspiciatis quibusdam, fuga provident at tempore
                          commodi tempora..{" "}
                          <span className="quote">
                            <sup> <i className="fa-solid fa-quote-right" /></sup>
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="carousel-item  testimonial ">
                      <img
                        src={Testii}
                        className=" testimonial-img"
                        alt="..."
                      />
                      <div className=" testimonial-card ">
                        <h3>Saul Goodman</h3>
                        <h4>CEO &amp; Founder</h4>
                        <p>
                          <span className="quote">
                            {" "}
                            <sup> <i className="fa-solid fa-quote-left" /></sup>
                          </span>
                          Some representative placeholder content for the first
                          slide Lorem ipsum dolor sit amet consectetur
                          adipisicing elit. Enim alias beatae laboriosam
                          perspiciatis quibusdam, fuga provident at tempore
                          commodi tempora..{" "}
                          <span className="quote">
                            <sup> <i className="fa-solid fa-quote-right" /></sup>
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="carousel-item  testimonial">
                      <img src={Testii} className="testimonial-img" alt="..." />
                      <div className=" testimonial-card ">
                        <h3>Saul Goodman</h3>
                        <h4>CEO &amp; Founder</h4>
                        <p>
                          <span className="quote">
                            {" "}
                            <sup> <i className="fa-solid fa-quote-left" /></sup>
                          </span>
                          Some representative placeholder content for the first
                          slide Lorem ipsum dolor sit amet consectetur
                          adipisicing elit. Enim alias beatae laboriosam
                          perspiciatis quibusdam, fuga provident at tempore
                          commodi tempora..{" "}
                          <span className="quote">
                            <sup> <i className="fa-solid fa-quote-right" /></sup>
                          </span>
                        </p>
                      </div>
                    </div> */}
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    />
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    />
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
              <div className="appointment-component col-md-6">
                <Appointment />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonials;
