import React from 'react'
import { Route, Routes } from 'react-router-dom'

import Home from '../Pages/Home'

import Aboutpage from '../Pages/Aboutpage/Aboutpage' 

import Training from '../Pages/Training/Training'

import StudyAbroaddetails from '../Pages/StudyAbroaddetails/StudyAbroaddetails'

import TestimonialsDetails from '../Pages/Testimonialspage/TestimonialsDetails'

import Blogpage from '../Pages/Blogpage/Blogpage'
import Blogdetailspage from '../Pages/Blogpage/Blogdetailspage'

import NewsDetails from '../Pages/NewsDetails/NewsDetails'
import NewsDetailsPage from '../Pages/NewsDetails/NewsDetailsPage'

import Appointment from '../Pages/Appointment/Appointment'
import Interviewpage from '../Pages/Interviewpage/Interviewpage'
import ApplyOnline from '../Pages/Appointment/ApplyOnline'



export default function Homerouting() {
  
  return (
    <>

    <Routes>
        <Route exact path='/' element={<Home/>}/>

        <Route exact path='/aboutpage' element={<Aboutpage/>}/>

        <Route exact path='/training/:id' element={<Training/>}/>
      
        <Route exact path='/studyabroad/:id' element={<StudyAbroaddetails />}/>
      
        <Route exact path='/testimonials' element={<TestimonialsDetails />}/>

        <Route exact path='/blogs' element={<Blogpage />}/>
        <Route exact path='/blogdetails/:slug' element={<Blogdetailspage />}/>

        <Route exact path='/news&events' element={<NewsDetails />}/>
        <Route exact path='/newsdetailspage/:slug' element={<NewsDetailsPage />}/>


        <Route exact path='/appointment' element={<Appointment/>}/>
        <Route exact path='/apply-online' element={<ApplyOnline />}/>
        <Route exact path='/services/:id' element={<Interviewpage/>}/>
        {/* <Route exact path='/ielts/:id' element={<Ielts/>}/> */}
        {/* <Route exact path='/trainingDetails' element={<TrainingDetails/>}/> */}
        
        

    </Routes>
    </>
  )
}
