import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

export default function Newsloader() {
    return (

        <>
            <div className='row'>
                <div className='col-md-4'>
                    <Stack spacing={1}>
                        <Skeleton variant="rectangular" width={350} height={300} />
                        <Skeleton variant="text" width={350} />
                        <Skeleton variant="text" width={350} />
                        <Skeleton variant="text" width={350} />
                    </Stack>
                </div>
                <div className='col-md-4'>
                    <Stack spacing={1}>
                        <Skeleton variant="rectangular" width={350} height={300} />
                        <Skeleton variant="text" width={350} />
                        <Skeleton variant="text" width={350} />
                        <Skeleton variant="text" width={350} />
                    </Stack>
                </div>
                <div className='col-md-4'>
                    <Stack spacing={1}>
                        <Skeleton variant="rectangular" width={350} height={300} />
                        <Skeleton variant="text" width={350} />
                        <Skeleton variant="text" width={350} />
                        <Skeleton variant="text" width={350} />
                    </Stack>
                </div>
            </div>
        </>



    )
}
