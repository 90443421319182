import axios from 'axios';
import React, { useEffect, useState } from 'react'

const useTrain = () => {
    const [train,setTrain]=useState([]);
    const [id,setId]=useState(null)
    const [loading,setLoading]=useState(false);
    const[singleTrain,setSingleTrain]=useState([])
    useEffect(()=>{
        setLoading(true);
        axios({
            url:`${process.env.REACT_APP_API_URL}/training/get`,
            method:'GET'
        }).then((res)=>{
            let response =res.data.data.trainings;
            setTrain(response);
            setLoading(false);
        }).catch((err)=>{
            console.log(err,"Your error is")
            setLoading(false);
        })

    },[])
    // single 
    useEffect(()=>{
       if(id){
        setLoading(true);
        axios({
            url:`${process.env.REACT_APP_API_URL}/training/get/${id}`,
            method:'GET'
        }).then((res)=>{
            let response =res.data.data.training;
            setSingleTrain(response);
            setLoading(false);
        }).catch((err)=>{
            console.log(err,"Your error is")
            setLoading(false);
        })
       }

    },[id])
  return {
    train,
    setTrain,
    id,setId,
    loading,setLoading,singleTrain,setSingleTrain
  }
}

export default useTrain